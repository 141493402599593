import React, { useEffect, useState } from "react";
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';

import { ResidentialOrdersTable } from "components/Tables";
import TRANSACTIONS_DATA from "data/transactions";
import { useAuth } from "../contexts/AuthContext"
import axios from "axios";
import PayloadGenerator from "./PayloadGenerator";

export default (props) => {

  const [transactions, setTransactions] = useState(props.transactions);
  const [proxyDetails, setProxyDetails] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");



  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription.toLowerCase();
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };

  return (
    <>
     <div >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-md-4">

          <h4>Residential Orders</h4>
          <p className="mb-0">Only active orders will be displayed, click on the order number to start the payload generator and generate access points massively.</p>
        </div>

      </div>


      <ResidentialOrdersTable
        transactions={transactions.filter(t => t.show)}
        hookProxyDetails={setProxyDetails}
      />
 
      {
        (Object.keys(proxyDetails).length > 0) && <PayloadGenerator proxyDetails={proxyDetails}></PayloadGenerator>
      }
      
      </div>
    </>
  );
};
