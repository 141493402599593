import React, { useState } from 'react';
import { Form, Button, Badge } from 'react-bootstrap';

export default (props) => {

    const { proxyDetails } = props;
    const [accessPoints, setAccessPoints] = useState(5);
    const [proxyFormat, setProxyFormat] = useState(1);
    const proxy = "access.proxyhive.io";
    const port = proxyDetails.port;
    const username = proxyDetails.username;
    const password = proxyDetails.password;
    const [country, setCountry] = useState('');
    const [sticky, setSticky] = useState(false);
    const [payloads, setPayloads] = useState([]);

  const countries = [
    'United States',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Brazil',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Chile',
    'China',
    'Colombia',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Estonia',
    'Ethiopia',
    'Finland',
    'France',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Guatemala',
    'Guyana',
    'Hashemite Kingdom of Jordan',
    'Hong Kong',
    'Hungary',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Kazakhstan',
    'Kenya',
    'Kosovo',
    'Kuwait',
    'Latvia',
    'Liechtenstein',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malaysia',
    'Mauritius',
    'Mexico',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of Lithuania',
    'Republic of Moldova',
    'Romania',
    'Russia',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Somalia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Thailand',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uzbekistan',
    'Venezuela',
    'Vietnam',
    'Zambia',
  ];


  const generateRandomSession = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const length = 8;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const generatePayloads = () => {
    const generatedPayloads = [];

    for (let i = 0; i < accessPoints; i++) {
      let payload = '';

      if (proxyFormat === 1) {
        payload = `${proxy}:${port}:${username}:${password}${
          country ? `_country-${country}` : ''
        }${sticky ? `_session-${generateRandomSession()}` : ''}`;
      } else if (proxyFormat === 2) {
        payload = `${username}:${password}${
          country ? `_country-${country}` : ''
        }${sticky ? `_session-${generateRandomSession()}` : ''}@${proxy}:${port}`;
      }

      generatedPayloads.push(payload);
    }

    setPayloads(generatedPayloads);
  };

  return (
    <div className='p-5'> 
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
        <div className="d-block mb-md-0">

          <h4>Payload Generator</h4>
          <p className="mb-0">Order selected <Badge bg="info" className="me-1">{proxyDetails.order_id}</Badge> </p>
        </div>

      </div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Access Points</Form.Label>
          <Form.Control
            type="number"
            value={accessPoints}
            onChange={(e) => setAccessPoints(parseInt(e.target.value))}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Proxy Format</Form.Label>
          <Form.Select
            value={proxyFormat}
            onChange={(e) => setProxyFormat(parseInt(e.target.value))}
          >
            <option value={1}>proxy:port:username:password</option>
            <option value={2}>username:password@proxy:port</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Country</Form.Label>
          <Form.Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="">Random Country</option>
            {countries.map((country, index) => (
              <option key={index} value={country.replace(/\s/g, '')}>
                {country}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Sticky session"
            checked={sticky}
            onChange={(e) => setSticky(e.target.checked)}
          />
        </Form.Group>
      </Form>
      <Button variant="primary" className="m-1" onClick={generatePayloads}>
        Generate Payloads
      </Button>
      <Form.Group className="mb-3">
        <Form.Label>Generated Payloads</Form.Label>
        <Form.Control
          as="textarea"
          rows="6"
          value={payloads.join('\n')}
          readOnly
        />
      </Form.Group>
    </div>
  );
}

