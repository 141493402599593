
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from 'react-bootstrap';

export default (props) => {
  const currentYear = moment().get("year");


  return (
    <div>

      <footer className="bg-white rounded shadow p-5 mb-4 mt-4">
        <Row>
          <Col xs={12} md={4} xl={6} className="mb-4 mb-md-0">
            <p className="mb-0 text-center text-lg-start">
              © 2023-{`${currentYear} `}
              <Card.Link href="https://proxyhive.io" target="_blank" className="text-primary fw-normal">
                Proxy Hive LLC  
              </Card.Link>
            </p>
          </Col>
          <Col xs={12} md={8} xl={6} className="text-center text-lg-start">
            <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://proxyhive.io/?page_id=1024" target="_blank">
                  FAQ
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://proxyhive.io/?page_id=3" target="_blank">
                Privacy Policy
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://proxyhive.io/?page_id=986" target="_blank">
                Refund policy
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://proxyhive.io/?page_id=978" target="_blank">
                Terms of service
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link onClick={() => window.open("https://proxyhive.io/#contact-us","_blank")} target="_blank">
                  Contact
                </Card.Link>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};
