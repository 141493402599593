
import React, { useState } from 'react';
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { RoutesPages } from "routes";
import { useAuth } from "contexts/AuthContext"

export default () => {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);

  const handleResetPassword = async () => {
    try {
      await resetPassword(email);
      setResetSent(true);
    } catch (error) {
      console.error('Error resetting password: ', error);
    }
  };

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={RoutesPages.Signin.path} className="d-flex align-items-center justify-content-center">
                <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to sign in
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                {resetSent ? (
                  <p className="mb-4">
                    We've sent a password reset link to your email. Please check your inbox.
                  </p>
                ) : (
                  <>
                    <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                    <Form>
                      <div className="mb-4">
                        <Form.Label htmlFor="email">Your Email</Form.Label>
                        <InputGroup id="email">
                          <Form.Control
                            required
                            autoFocus
                            type="email"
                            placeholder="john@company.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className="d-grid">
                        <Button
                          variant="gray-800"
                          type="button" // Use type="button" to prevent form submission
                          onClick={handleResetPassword}
                        >
                          Recover password
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
