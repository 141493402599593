
import React from "react";
import { CloudUploadIcon, CollectionIcon, FireIcon, PlusIcon, ShieldExclamationIcon, UserAddIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown } from 'react-bootstrap';

import { CustomersWidget, RevenueWidget, UsersWidget, WeeklyReportWidget, TopAuthorsWidget, TeamMembersWidget, ProgressTrackWidget, EventsWidget, RankingWidget, VisitsMapWidget, BandwidthWidget, AcquisitionWidget, TimelineWidget } from "components/Widgets";
import { PageVisitsTable } from "components/Tables";

const DashboardOverview = () => {
  return (
    <>
      <div className="py-4">

      </div>

      <Row className="justify-content-lg-center">
        <Col xs={12} className="mb-4">
          
          <BandwidthWidget
            title="Bandwidth Used"
            value="10,567"
          />
        </Col>
        
      </Row>

    </>
  );
};


export default DashboardOverview