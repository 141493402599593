import React, { useState } from "react";
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon, CreditCardIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';

import { TransactionsTable } from "components/Tables";
import { Link } from 'react-router-dom';
import { RoutesPages } from "routes";

export default (props) => {
  const [transactions, setTransactions] = useState(props.data.balance_history.map(t => ({ ...t, show: true })));
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription;
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

        <div className="d-block mb-4 mb-md-0">

          <h4>Transactions</h4>
          <p className="mb-0">Deposits and Orders will be displayed</p>
        </div>

        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" as={Link} to={RoutesPages.Billing.path}>
            <PlusIcon className="icon icon-xs me-2" /> New Plan or Add balance
          </Button>


        </div>
      </div>



      <TransactionsTable
        transactions={transactions.filter(t => t.show)}
      />
    </>
  );
};
