import { v4 as uuidv4 } from "uuid";

export default [
    {
        "id": uuidv4(),
        "priceMonthly": 5,
        "youGet": " GB",
        "priceYearly": 0,
        "title": "Residential",
        "btnText": "Start with Residential",
        "description": "Residental proxies powered by bandwidth sharing around the world.",
        "features": [
            "Rotation in each request",
            "Worldwide proxies",
            "Geolocation",
            "Sticky IP per session",
            "Public Share & Comments",
            "Authentication by username and password"
        ]
    },
    {
        "id": uuidv4(),
        "priceMonthly": 4,
        "priceYearly": 0,
        "youGet": " Port / Day",
        "title": "Mobile",
        "btnText": "Start with Mobile",
        "description": "Mobile proxies powered by bandwidth sharing around the US.",
        "features": [
            "Unlimited bandwidth",
            "Up to 25 ports",
            "Geolocation by state",
            "ATT, TMOBILE, VERIZON",
            "Mobile proxies in the United States",
            "HTTPS or SOCKS5",
            "Authentication by whitelist IP"
        ]
    },
    {
        "id": uuidv4(),
        "priceMonthly": 4,
        "priceYearly": 0,
        "youGet": " Port / Day",
        "title": "Mobile + WIFI",
        "btnText": "Start with Mobile + WIFI",
        "btnTextColor": "dark",
        "description": "Mobile and WIFI proxies powered by bandwidth sharing around the US.",
        "features": [
            "Unlimited bandwidth",
            "Up to 25 ports",
            "Geolocation by state",
            "US Mobile and Internet Providers",
            "HTTPS or SOCKS5",
            "Authentication by whitelist IP"
        ]
    }
]