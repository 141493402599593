
import React, { useState } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, FormCheck, Breadcrumb } from 'react-bootstrap';

import AccordionComponent from "components/AccordionComponent";
import { PriceCardWidget } from "components/Widgets";
import pricings from "data/pricings";


export default () => {
  const [isPriceMonthly, setIsPriceMonthly] = useState(true);
  const priceType = isPriceMonthly ? "monthly" : "annual";

  const togglePriceType = () => {
    setIsPriceMonthly(!isPriceMonthly);
  };

  return (
    <>
      <div className="d-block py-4">

        <h3>Choose the best plan for your business</h3>
        <p className="mb-0">
        You can get a residential or mobile plan in the deposit section
        </p>
      </div>

      <Card border="0" className="shadow mb-5">
        <Card.Body>
          <Row className="text-gray">

            {pricings.map(pricing => (
              <React.Fragment key={`pricing-${pricing.id}`}>
                <Col xs={12} lg={6} xl={4}>
                  <PriceCardWidget priceType={priceType} {...pricing} />
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Card.Body>
      </Card>

      <Card border="0" className="shadow py-4">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} xl={6}>
            <Card.Header className="border-0 text-center">
              <h3 className="mb-4">Frequently asked questions</h3>
              <p className="text-gray">Here’s what you need to know about your Proxy Hive plans, based on the questions we get asked the most.</p>
            </Card.Header>
            <Card.Body>
              <AccordionComponent
                defaultKey="panel-1"
                data={[
                  {
                    id: 1,
                    eventKey: "panel-1",
                    title: "Can I get a refund for my mobile proxy order?",
                    description: "Mobile proxy orders can only be canceled within the first 24 hours of placing the order, provided they were placed within the last 30-31 days. After 24 hours, cancellations for mobile proxy orders will not be accepted. Please refer to our refund policy for more details."
                  },
                  {
                    id: 2,
                    eventKey: "panel-2",
                    title: "How are refunds handled for residential proxies?",
                    description: "Residential proxy orders can be canceled at any time. If you cancel a residential proxy order, you will receive a refund for the remaining bandwidth. However, please note that the data already used or consumed will not be refunded."
                  },
                  {
                    id: 3,
                    eventKey: "panel-3",
                    title: "Is my payment information secure?",
                    description: "We take the security of your payment information seriously. Our website uses industry-standard encryption protocols to protect your data during transmission. Additionally, we comply with applicable data protection laws and do not store your payment information on our servers."
                  },
                  {
                    id: 4,
                    eventKey: "panel-4",
                    title: "Will I receive a refund for order extensions on mobile proxies?",
                    description: "No, order extensions or additional services purchased for mobile proxies are non-refundable, even if the original order is canceled within the eligible cancellation period."
                  },
                  {
                    id: 5,
                    eventKey: "panel-5",
                    title: "What payment methods do you accept?",
                    description: "We accept various payment methods, including credit, debit and prepaid card, paypal, chime and more (you can contact us for more). You can choose the most convenient option during the checkout process. Please note that the availability of specific payment methods may vary depending on your location."
                  }
                ]} />
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  );
};
