
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { ExclamationIcon, InboxIcon, RefreshIcon, ShoppingCartIcon } from "@heroicons/react/solid";

import { RoutesPages } from "routes";
import ProxyHiveProfile from "assets/img/team/favicon.png"

export const userNotifications = [
    {
        "id": uuidv4(),
        "read": false,
        "image": ProxyHiveProfile,
        "sender": "Proxy Hive",
        "time": moment().subtract(15, "seconds"),
        "link": RoutesPages.DashboardTraffic.path,
        "message": `Welcome to proxy hive, welcome to freedom.`
    }
];

export const productNotifications = [
    {
        "id": uuidv4(),
        "title": "You sold an item",
        "time": moment().subtract(1, "minute"),
        "icon": ShoppingCartIcon,
        "iconBg": "purple",
        "message": "Bonnie Green just purchased \"Volt - Admin Dashboard\"!",
    },
    {
        "id": uuidv4(),
        "title": "New message",
        "time": moment().subtract(8, "minutes"),
        "icon": InboxIcon,
        "iconBg": "primary",
        "message": "Let's meet at Starbucks at 11:30. Wdyt?",
    },
    {
        "id": uuidv4(),
        "title": "Product issue",
        "time": moment().subtract(10, "minutes"),
        "icon": ExclamationIcon,
        "iconBg": "warning",
        "message": "A new issue has been reported for Pixel Pro.",
    },
    {
        "id": uuidv4(),
        "title": "Product update",
        "time": moment().subtract(4, "hours"),
        "icon": RefreshIcon,
        "iconBg": "success",
        "message": "Spaces - Listings Template has been updated.",
    },
    {
        "id": uuidv4(),
        "title": "Product update",
        "time": moment().subtract(5, "hours"),
        "icon": RefreshIcon,
        "iconBg": "success",
        "message": "Volt - Admin Dashboard has been updated.",
    },
];