
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { RoutesPages } from "routes";

import PrivateRoute from '../PrivateRoute'

// pages
import Presentation from "pages/Presentation";
import DashboardOverview from "pages/dashboard/DashboardOverview";
import DashboardTraffic from "pages/dashboard/DashboardTraffic";
import DashboardProductAnalysis from "pages/dashboard/DashboardProductAnalysis";
import Kanban from 'pages/Kanban';
import Messages from "pages/Messages";
import SingleMessage from "pages/SingleMessage";
import Users from "pages/Users";
import Transactions from "pages/Transactions";
import Tasks from "pages/Tasks";
import Settings from "pages/Settings";
import Calendar from "pages/Calendar";
import MapPage from "pages/Map";
import Datatables from "pages/tables/Datatables";
import BootstrapTables from "pages/tables/BootstrapTables";
import Pricing from "pages/examples/Pricing";
import Billing from "pages/examples/Billing";
import Invoice from "pages/examples/Invoice";
import Signin from "pages/Signin";
import Signup from "pages/examples/Signup";
import ForgotPassword from "pages/examples/ForgotPassword";
import ResetPassword from "pages/examples/ResetPassword";
import Lock from "pages/examples/Lock";
import Widgets from "pages/examples/Widgets";
import NotFoundPage from "pages/examples/NotFound";
import ServerError from "pages/examples/ServerError";

// documentation pages
import DocsOverview from "pages/documentation/DocsOverview";
import DocsDownload from "pages/documentation/DocsDownload";
import DocsQuickStart from "pages/documentation/DocsQuickStart";
import DocsLicense from "pages/documentation/DocsLicense";
import DocsFolderStructure from "pages/documentation/DocsFolderStructure";
import DocsBuild from "pages/documentation/DocsBuild";
import DocsChangelog from "pages/documentation/DocsChangelog";

// plugin pages
import PluginCharts from "pages/plugins/Charts";
import PluginCalendar from "pages/plugins/Calendar";
import PluginDatatable from "pages/plugins/Datatable";
import PluginMap from "pages/plugins/Map";
import PluginDropzone from "pages/plugins/Dropzone";
import PluginSweetAlert from "pages/plugins/SweetAlert";

// components
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';

import Accordion from "pages/components/Accordion";
import Alerts from "pages/components/Alerts";
import Badges from "pages/components/Badges";
import Breadcrumbs from "pages/components/Breadcrumbs";
import Buttons from "pages/components/Buttons";
import Forms from "pages/components/Forms";
import Modals from "pages/components/Modals";
import Navs from "pages/components/Navs";
import Navbars from "pages/components/Navbars";
import Pagination from "pages/components/Pagination";
import Popovers from "pages/components/Popovers";
import Progress from "pages/components/Progress";
import Tables from "pages/components/Tables";
import Tabs from "pages/components/Tabs";
import Tooltips from "pages/components/Tooltips";
import Toasts from "pages/components/Toasts";
import WidgetsComponent from "pages/components/Widgets";

import { doc, getDoc} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ResidentialOrders from './ResidentialOrders';
import axios from 'axios';
import MobileOrders from './MobileOrders';


const RouteWithSidebar = ({ children }) => {

  const { currentUser, base } = useAuth()
  const [userData, setUserData] = useState({
    balance: 'loading',
    balance_history: [],
    last_name: 'loading',
    name: 'loading',
    orders: 'loading',
    telegram: 'loading'
  } )
  const [loading, setLoading] = useState(false)
  const [transactions, setTransactions] = useState([]);
  const [mobileActiveOrdersHook, setMobileActiveOrdersHook] = useState([]);

  const getOrderDetails = async () => {

    const url = `${base}/ui/order_details`;

    const payload = {
      "uid": currentUser.uid,
      "network" : 'RESIDENTIAL'
    }

    await axios.post(url, payload)
      .then(res => {
        setTransactions(res.data.map(t => ({ ...t, show: true })))
      })
      .catch(err => {
        console.log(err)
      });

      const payload2 = {
        "uid": currentUser.uid,
        "network" : 'MOBILE'
      }
  
      await axios.post(url, payload2)
        .then(res => {
          setMobileActiveOrdersHook(res.data.map(t => ({ ...t, show: true })))
        })
        .catch(err => {
          console.log(err)
        });
  };

  

  const getDataOfUser = async () => {
    
    const db = getFirestore()
    const docRef = doc(db, 'users', currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let aux = docSnap.data()
      aux.email = currentUser.email
      aux.balance_history = aux.balance_history.reverse()
        setUserData(aux)
        await getOrderDetails()
        setLoading(true)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setLoading(true)
      }
      
  }



  useEffect(() => {
    getDataOfUser()
  }, [currentUser])

  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  }

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'true' ? true : false
  }

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'true' ? true : false
  }

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    
      <>

<Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

      {loading  &&
      <>
      
      <Sidebar
          contracted={contractSidebar}
          onMouseEnter={toggleMouseOver}
          onMouseLeave={toggleMouseOver}
        />

        <main className="content">
          <Topbar toggleContracted={toggleContracted} />
          {React.cloneElement(children, {data: userData, refresh: getDataOfUser, transactions: transactions, mobileActiveOrders: mobileActiveOrdersHook})}
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
      
      }

      </>

  );
};

export default () => (

  
  <Routes>
    
    <Route exact path={RoutesPages.Signin.path} element={<Signin/>} />
    <Route exact path={RoutesPages.Signup.path} element={<Signup/>} />
    <Route exact path={RoutesPages.ForgotPassword.path} element={<ForgotPassword/>} />
    <Route exact path={RoutesPages.ResetPassword.path} element={<ResetPassword/>} />
    <Route exact path={RoutesPages.Lock.path} element={<Lock/>} />
    <Route exact path={RoutesPages.NotFound.path} element={<NotFoundPage/>} />
    <Route exact path={RoutesPages.ServerError.path} element={<ServerError/>} />

    {/* pages */}
    <Route exact path={RoutesPages.Presentation.path} element={<PrivateRoute><RouteWithSidebar><DashboardTraffic /></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DashboardOverview.path} element={<PrivateRoute><RouteWithSidebar><DashboardOverview/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DashboardTraffic.path} element={<PrivateRoute><RouteWithSidebar><DashboardTraffic /></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DashboardProductAnalysis.path} element={<PrivateRoute><RouteWithSidebar><DashboardProductAnalysis/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Kanban.path} element={<PrivateRoute><RouteWithSidebar><Kanban/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Messages.path} element={<PrivateRoute><RouteWithSidebar><Messages/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.SingleMessage.path} element={<PrivateRoute><RouteWithSidebar><SingleMessage/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Users.path} element={<PrivateRoute><RouteWithSidebar><Users/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Transactions.path} element={<PrivateRoute><RouteWithSidebar><Transactions/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.ResidentialOrders.path} element={<PrivateRoute><RouteWithSidebar><ResidentialOrders/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.MobileOrders.path} element={<PrivateRoute><RouteWithSidebar><MobileOrders/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Tasks.path} element={<PrivateRoute><RouteWithSidebar><Tasks/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Settings.path} element={<PrivateRoute><RouteWithSidebar><Settings/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Calendar.path} element={<PrivateRoute><RouteWithSidebar><Calendar/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Map.path} element={<PrivateRoute><RouteWithSidebar><MapPage/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Datatables.path} element={<PrivateRoute><RouteWithSidebar><Datatables/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.BootstrapTables.path} element={<PrivateRoute><RouteWithSidebar><BootstrapTables/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Pricing.path} element={<PrivateRoute><RouteWithSidebar><Pricing/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Billing.path} element={<PrivateRoute><RouteWithSidebar><Billing/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Invoice.path} element={<PrivateRoute><RouteWithSidebar><Invoice/></RouteWithSidebar></PrivateRoute>} />

    {/* components */}
    <Route exact path={RoutesPages.Accordions.path} element={<PrivateRoute><RouteWithSidebar><Accordion/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Alerts.path} element={<PrivateRoute><RouteWithSidebar><Alerts/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Badges.path} element={<PrivateRoute><RouteWithSidebar><Badges/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Widgets.path} element={<PrivateRoute><RouteWithSidebar><Widgets/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Breadcrumbs.path} element={<PrivateRoute><RouteWithSidebar><Breadcrumbs/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Buttons.path} element={<PrivateRoute><RouteWithSidebar><Buttons/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Forms.path} element={<PrivateRoute><RouteWithSidebar><Forms/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Modals.path} element={<PrivateRoute><RouteWithSidebar><Modals/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Navs.path} element={<PrivateRoute><RouteWithSidebar><Navs/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Navbars.path} element={<PrivateRoute><RouteWithSidebar><Navbars/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Pagination.path} element={<PrivateRoute><RouteWithSidebar><Pagination/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Popovers.path} element={<PrivateRoute><RouteWithSidebar><Popovers/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Progress.path} element={<PrivateRoute><RouteWithSidebar><Progress/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Tables.path} element={<PrivateRoute><RouteWithSidebar><Tables/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Tabs.path} element={<PrivateRoute><RouteWithSidebar><Tabs/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Tooltips.path} element={<PrivateRoute><RouteWithSidebar><Tooltips/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.Toasts.path} element={<PrivateRoute><RouteWithSidebar><Toasts/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.WidgetsComponent.path} element={<PrivateRoute><RouteWithSidebar><WidgetsComponent/></RouteWithSidebar></PrivateRoute>} />

    {/* documentation */}
    <Route exact path={RoutesPages.DocsOverview.path} element={<PrivateRoute><RouteWithSidebar><DocsOverview/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsDownload.path} element={<PrivateRoute><RouteWithSidebar><DocsDownload/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsQuickStart.path} element={<PrivateRoute><RouteWithSidebar><DocsQuickStart/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsLicense.path} element={<PrivateRoute><RouteWithSidebar><DocsLicense/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsFolderStructure.path} element={<PrivateRoute><RouteWithSidebar><DocsFolderStructure/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsBuild.path} element={<PrivateRoute><RouteWithSidebar><DocsBuild/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.DocsChangelog.path} element={<PrivateRoute><RouteWithSidebar><DocsChangelog/></RouteWithSidebar></PrivateRoute>} />

    {/* plugins */}
    <Route exact path={RoutesPages.PluginCharts.path} element={<PrivateRoute><RouteWithSidebar><PluginCharts/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginCalendar.path} element={<PrivateRoute><RouteWithSidebar><PluginCalendar/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginDatatable.path} element={<PrivateRoute><RouteWithSidebar><PluginDatatable/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginMap.path} element={<PrivateRoute><RouteWithSidebar><PluginMap/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginDropzone.path} element={<PrivateRoute><RouteWithSidebar><PluginDropzone/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginSweetAlert.path} element={<PrivateRoute><RouteWithSidebar><PluginSweetAlert/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.PluginSweetAlert.path} element={<PrivateRoute><RouteWithSidebar><PluginSweetAlert/></RouteWithSidebar></PrivateRoute>} />
    <Route exact path={RoutesPages.NotFound.path} element={<NotFoundPage/>} />
    <Route path="*" element={<Navigate to={RoutesPages.NotFound.path} />} />
    </Routes>

);
