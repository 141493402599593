import React, { useState } from 'react';

import { v4 as uuidv4 } from "uuid";

import { ChevronDownIcon, CubeIcon, CurrencyDollarIcon } from "@heroicons/react/solid";
import { Col, Row, Button, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { LineGraphChartWidget } from "components/Widgets";

import moment from "moment-timezone";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useAuth } from "../../contexts/AuthContext"

export default (props) => {

  const [loading, setLoading] = useState(false)
  const [trafficVolumes, setTrafficVolumes] = useState([])
  const [orderID, setOrderID] = useState("")

  const { base } = useAuth()

  const handlerLineGraphChartWidget = async (order) => {
    setLoading(true)

    const url = `${base}/ui/get_order_bandwidth`;

    const payload = {
      "order": order
    }
    
    const result = await axios.post(url, payload)
    .then(res => {
      return(res.data)
    })
    .catch(err => {
      console.log(err)
      return({})
      
    });
    console.log(result);

    let objectUp = []
    let objetctDown = []
    let objectTotal = []
    if (Object.keys(result.bandwidth_history).length !== 0) {
      Object.keys(result.bandwidth_history).forEach(function (key) {

        objectUp.push({ value: parseFloat((result.bandwidth_history[key]['bandwidth_upload_mb'] / 1024).toFixed(2)), date: moment(key).format("DD MMM YY") })
        objetctDown.push({ value: parseFloat((result.bandwidth_history[key]['bandwidth_download_mb'] / 1024).toFixed(2)), date: moment(key).format("DD MMM YY") })
        objectTotal.push({ value: parseFloat((result.bandwidth_history[key]['bandwidth_upload_mb'] / 1024 + result.bandwidth_history[key]['bandwidth_download_mb'] / 1024).toFixed(2)), date: moment(key).format("DD MMM YY") })

      });
    } else {
      objectUp.push({ value: parseFloat((0).toFixed(2)), date: moment().format("DD MMM YY") })
      objetctDown.push({ value: parseFloat((0).toFixed(2)), date: moment().format("DD MMM YY") })
      objectTotal.push({ value: parseFloat((0).toFixed(2)), date: moment().format("DD MMM YY") })

    }

    const auxTrafficVolumes = [
      {
          id: uuidv4(),
          label: "Total",
          color: "#1F2937",
          values: objectTotal.reverse(),
      },
      {
          id: uuidv4(),
          label: "GB Uploaded",
          color: "#3cb371",
          values: objectUp.reverse(),
      },
      {
          id: uuidv4(),
          label: "GB Downloaded",
          color: "#f08080",
          values: objetctDown.reverse(),
      },
  
  ];

  setTrafficVolumes(auxTrafficVolumes)
  setOrderID(order)

    setLoading(false)
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown>
          <Dropdown.Toggle as={Button} variant="gray-800" className="d-inline-flex align-items-center dropdown-toggle me-2">
            Select an active proxy plan <ChevronDownIcon className="icon icon-xs ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
            {
              props.transactions.map((t, i) => (
                <Dropdown.Item className="d-flex align-items-center" key={`order-id${i}`} onClick={() => handlerLineGraphChartWidget(t.order_id)}>
                  <CubeIcon className="icon icon-xs text-gray-400 me-2" /> {`${t.order_id}`}
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>

        <OverlayTrigger
          key="currentBalance"
          overlay={<Tooltip id="top" className="m-0">Your current balance</Tooltip>}
        >

          <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" style={{ margin: 10 }}>
            <CurrencyDollarIcon className="icon icon-xs text-gray-400 me-2" /> {parseFloat(props.data.balance).toFixed(2)}
          </Button>

        </OverlayTrigger>

      </div>

      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
      <Row>

        <Col xs={12} xl={12} xxl={12} className="mb-4">
        {!loading  &&
        <>
        
        
        <LineGraphChartWidget
            title={`Bandwidth Consumed GB per Day ${orderID ? `| Order ID ${orderID}` : ``}`}
            data={trafficVolumes}
          />
        
        
        </>
                
        }


        </Col>
      </Row>

    </>
  );
};
