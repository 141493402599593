import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ArchiveIcon, CalendarIcon, ChatIcon, ChevronDownIcon, ClipboardListIcon, CloudUploadIcon, DocumentTextIcon, FireIcon, PlusIcon, PresentationChartBarIcon, ShoppingBagIcon, UsersIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Image, Button, Dropdown } from 'react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget, NotificationsWidget } from "components/Widgets";
import { GeneralInfoForm } from "components/Forms";
import { Link } from 'react-router-dom';

import { RoutesPages } from "routes";
import Profile1 from "assets/img/team/profile-picture-1.jpg";
import Profile3 from "assets/img/team/profile-picture-3.jpg";
import ProfileCover from "assets/img/profile-cover.jpg";


export default (props) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: files => setFiles(files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file)
    })))
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">
          {path}
        </Card.Text>
      </Col>
    );
  };

  return (
    <>
      
      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm data={props.data} />
        </Col>
      </Row>
    </>
  );
};
