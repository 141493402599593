import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"
import firebase from 'firebase/compat/app';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
} 

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const base = "https://api.proxyhive.io"
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

async function updatePassword(currentPassword, newPassword) {
  // Reautentica al usuario con su contraseña actual
  const credentials = firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
  
  try {
    await currentUser.reauthenticateWithCredential(credentials);
    await currentUser.updatePassword(newPassword);
    return true; // Éxito
  } catch (error) {
    throw new Error(error.message); // Lanza una excepción con el mensaje de error
  }
}
  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
      
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    base
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
