import React, { useEffect, useState } from "react";
import { CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Form, Button, Card, Spinner, Alert, Modal, Badge } from 'react-bootstrap';

import { MobileOrdersTable } from "components/Tables";
import TRANSACTIONS_DATA from "data/transactions";
import { useAuth } from "../contexts/AuthContext"
import axios from "axios";
import PayloadGenerator from "./PayloadGenerator";

export default (props) => {
 console.log("im a bitch",props.mobileActiveOrders);

  const [transactions, setTransactions] = useState(props.mobileActiveOrders);
  const [proxyDetails, setProxyDetails] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("all");
  const [showModal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestResult, setRequestResult] = useState("");
  const [requestResult2, setRequestResult2] = useState("");
  const { base } = useAuth()

  const handleClose = () => {
    setProxyDetails({})
  }


  const updateOrderHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    const urlWip = `${base}/ui/update_wip`;
    const urlIpRotation = `${base}/ui/update_rotation`;
    if (data.get('whitelist_ip')) {
      const payload = {
        //"uid" : currentUser.uid,
        "order" : proxyDetails.order_id,
        "wip" : data.get('whitelist_ip')
      }
  
      await axios.post(urlWip, payload)
          .then(res => {
            console.log(res);
            if (res.data.body === "Error: ip_still_locked ") {
              setRequestResult("IP white list still locked")
            }else{
              setRequestResult(res.data.body)
            }
            
            //props.refresh()
            
          })
          .catch(err => {
              setRequestResult(err)

                      
          });
    }
    
    if (data.get('ip_rotation')) {
      const payload2 = {
        //"uid" : currentUser.uid,
        "order" : proxyDetails.order_id,
        "rotation" : (data.get('ip_rotation') == "1" ? "TRUE" : "FALSE")
      }
  
      await axios.post(urlIpRotation, payload2)
          .then(res => {
            console.log(res);
            setRequestResult2(res.data.body)
            //props.refresh()
            
          })
          .catch(err => {
              setRequestResult2(err)
              console.log(err);
          });
    }

        setLoading(false)
        
};
  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newTransactions = transactions.map(t => {
      const subscription = t.subscription.toLowerCase();
      const shouldShow = subscription.includes(newSearchValue)
        || `${t.price}`.includes(newSearchValue)
        || t.status.includes(newSearchValue)
        || `${t.invoiceNumber}`.includes(newSearchValue);

      return ({ ...t, show: shouldShow });
    });

    setSearchValue(newSearchValue);
    setTransactions(newTransactions);
  };

  const changeStatusValue = (e) => {
    const newStatusValue = e.target.value;
    const newTransactions = transactions.map(u => ({ ...u, show: u.status === newStatusValue || newStatusValue === "all" }));

    setStatusValue(newStatusValue);
    setTransactions(newTransactions);
  };

  return (
    <>
     <div >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block  mb-md-4">

          <h4>Mobile Orders</h4>
          <p className="mb-0">Only active orders will be displayed, click on the order number to start the payload generator and generate access points massively.</p>
        </div>

      </div>


      <MobileOrdersTable
        transactions={transactions.filter(t => t.show)}
        hookProxyDetails={setProxyDetails}
      />


        <Modal centered show={(Object.keys(proxyDetails).length > 0)} onHide={handleClose}>
  <Modal.Header className="border-0">
    <Button variant="close" aria-label="Close" onClick={handleClose} />
  </Modal.Header>
  <Modal.Body className="p-0">
    <Card className="px-0 px-md-4 py-0 border-0">
      <Card.Header className="border-0 text-center text-md-center mb-0 mt-md-0">
        <h4>Update your order <Badge bg="info"  className="me-1">{proxyDetails.order_id}</Badge> </h4>
      </Card.Header>
      <Card.Body>
        <Form action="#" onSubmit={updateOrderHandler}>

          <Form.Group>
            <Form.Group className="mb-3" id="exampleInputPasswordCard1">
              <Form.Label>WHITELIST IP {proxyDetails.wip} </Form.Label>
              <p>You can change your ip access once every 30 minutes</p>
              <Form.Control name="whitelist_ip" type="text" placeholder="Enter your new IP in the whitelist" />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>IP ROTATION</Form.Label>
              <Form.Select name="ip_rotation">
                <option value="1">30 Minutes</option>
                <option value="0">6 Hours</option>
              </Form.Select>
            </Form.Group>

          </Form.Group>
          {requestResult &&
            <Alert variant="warning" className="mb-2">
              {requestResult}
            </Alert>
          }

          {requestResult2 &&
                      <Alert variant="warning" className="mb-2">
                        {requestResult2}
                      </Alert>
                    }
          <div className="d-grid">
            <Button variant="gray-800" type="submit" disabled={loading}>
              {loading ?
                <>
                  <Spinner animation="border" size="sm" />
                  <span className="ms-2">Loading...</span>
                </>
                :
                `Update`
              }
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  </Modal.Body>
</Modal>


      
      </div>
    </>
  );
};
