import React, { useState } from "react";
import { Col, Row, Card, Form, Button, Modal, InputGroup, Tooltip, OverlayTrigger, Spinner, Alert  } from 'react-bootstrap';
import { HomeIcon, CurrencyDollarIcon, DeviceMobileIcon } from "@heroicons/react/solid";
import { Coinbase } from "components/Forms";
import { OrderHistoryWidget, ChangePlanWidget } from "components/Widgets";

import { DocumentTextIcon, GlobeIcon, WifiIcon} from "@heroicons/react/solid";
import { FacebookIcon, GithubIcon, TwitterIcon } from "components/BrandIcons";

import Nouislider from "nouislider-react";
import wNumb from 'wnumb'
import { useAuth } from "../../contexts/AuthContext"
import axios from 'axios';


export default (props) => {

  const mobilePrice = 4
  const ResidentialPrice = 5


  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  const [showResidential, setshowResidential] = useState(false);
  const [showMobile, setshowMobile] = useState(false);
  const [requestResult, setRequestResult] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setshowResidential(false)
    setshowMobile(false)
  }

  const { currentUser, base } = useAuth()


  const ResidentialSubmitHandler = async (event) => {
    event.preventDefault();
    setLoading(true)
    const url = `${base}/ui/buy_proxy`;

    const payload = {
      "uid" : currentUser.uid,
      "type" : "RESIDENTIAL",
      "gb_amount" : value
    }

    await axios.post(url, payload)
        .then(res => {
          setRequestResult(res.data.body)
          props.refresh()
          
        })
        .catch(err => {
            setRequestResult(err)
        });
        setLoading(false)

        
};

const MobileSubmitHandler = async (event) => {
  event.preventDefault();
  const data = new FormData(event.currentTarget);
  
  setLoading(true)
  const url = `${base}/ui/buy_proxy`;

  const payload = {
    "uid" : currentUser.uid,
    "type" : "MOBILE",
    "connection_type" : data.get('connection_type'),
    "ports" : valueMobile,
    "days" : valueDays[0],
    "protocol" : data.get('protocol'),
    "wip" : data.get('whitelist_ip'),
    "rotation" : ( data.get('ip_rotation')  === "1" ? true : false)
  }

  await axios.post(url, payload)
      .then(res => {
        setRequestResult(res.data.body)
        props.refresh()
        
      })
      .catch(err => {
          setRequestResult(err)
      });
      setLoading(false)

      
      
};


  const [value, setValue] = useState(1);
  const [valueMobile, setValueMobile] = useState(1);
  const [valueDays, setValueDays] = useState([1]);

  return (
    <>

      <div className="btn-toolbar mb-2 mb-md-4">

        <Button onClick={() => setshowResidential(true)} variant="gray-800" size="sm" className="d-inline-flex align-items-center " style={{ margin: 10 }}>
          <HomeIcon className="icon icon-xs me-2" /> Residential Plan
        </Button>

        <Button onClick={() => setshowMobile(true)} variant="gray-800" size="sm" className="d-inline-flex align-items-center " style={{ margin: 10 }}>
          <DeviceMobileIcon className="icon icon-xs me-2" /> Mobile Plan
        </Button>

        <OverlayTrigger
          key="currentBalance"
          overlay={<Tooltip id="top" className="m-0">Your current balance</Tooltip>}
        >

          <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" style={{ margin: 10 }}>
            <CurrencyDollarIcon className="icon icon-xs text-gray-400 me-2" /> {parseFloat(props.data.balance).toFixed(2)}
          </Button>

        </OverlayTrigger>

      </div>
      <Coinbase />









      <Modal centered show={showResidential} onHide={handleClose}>
        <Modal.Header className="border-0">
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card className="px-0 px-md-4 py-0 border-0">
            <Card.Header className="border-0 text-center text-md-center mb-0 mt-md-0">
              <h4><HomeIcon className="icon icon-xs me-2" />  Residential network</h4>
            </Card.Header>
            <Card.Body>
              <Form action="#" onSubmit={ResidentialSubmitHandler}>

                <Form.Group>
                  <Form.Group className="mb-3" id="exampleInputPasswordCard1">

                    <Nouislider
                      connect="lower"
                      className="input-slider"
                      start={1}
                      range={{ min: 1, max: 200 }}
                      tooltips={true}
                      format={wNumb({ decimals: 0 })}
                      step={1}
                      onSlide={(range) => setValue(range)}
                    />
                    <Form.Label className="mt-3"># GB Bandwidth </Form.Label>

                  </Form.Group>
                  <Form.Group id="use" className="mb-2">
                    <Form.Label>How will you use your proxies?</Form.Label>
                    <InputGroup>
                      <InputGroup.Text className="text-gray-600"><DocumentTextIcon className="icon icon-xs" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Scraping, SEO, Ads, etc..." />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-mb-2">Total: $ {value * ResidentialPrice} - ( {ResidentialPrice} USD / GB ) </Form.Label>
                  </Form.Group>
                </Form.Group>
                {requestResult &&
                    <Alert variant="info">
                    {requestResult}
                  </Alert>
                  }
                <div className="d-grid">

                
                  <Button variant="gray-800" type="submit" disabled={loading}>
                  
                  {loading ?
                  <>
                  <Spinner animation="border" size="sm" />
                  <span className="ms-2">Loading...</span>
                  </>
                  
                   
                   :
                   `Order`
                  }
                  </Button>
                </div>
              </Form>


            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>





      <Modal centered show={showMobile} onHide={handleClose}>
        <Modal.Header className="border-0">
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card className="px-0 px-md-4 py-0 border-0">
            <Card.Header className="border-0 text-center text-md-center mb-0 mt-md-0">
              <h4> <DeviceMobileIcon className="icon icon-xs me-2" />Mobile | <WifiIcon className="icon icon-xs me-2" />WIFI network</h4>
            </Card.Header>
            <Card.Body>
              <Form action="#" onSubmit={MobileSubmitHandler}>


                <Form.Group>
                  <Form.Group className="mb-5" id="exampleInputPasswordCard1">

                    <Nouislider
                      connect="lower"
                      className="input-slider"
                      start={1}
                      range={{ min: 1, max: 25 }}
                      tooltips={true}
                      step={1}
                      format={wNumb({ decimals: 0 })}
                      onSlide={(range) => setValueMobile(range)}
                    />
                    <Form.Label className="mt-3"> # Ports </Form.Label>

                  </Form.Group>

                  <Form.Group className="mb-3" id="exampleInputPasswordCard1">

                    <Nouislider
                      connect="lower"
                      className="input-slider"
                      start={1}
                      range={{ min: 1, max: 31 }}
                      tooltips={true}
                      format={wNumb({ decimals: 0 })}
                      step={1}
                      onSlide={(range) => setValueDays(range)}
                    />
                    <Form.Label className="mt-3"> # Days </Form.Label>

                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Network type</Form.Label>
                    <Form.Select name="connection_type">
                      <option defaultValue value="4G_MOBILE">Mobile</option>
                      <option value="WIFI">Wifi</option>
                      <option value="CARRIER">Mobile and WIFI</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group  className="mb-2">
                    <Form.Label>Protocol</Form.Label>
                    <Form.Select name="protocol">
                      <option defaultValue value="HTTPS">HTTPS</option>
                      <option value="SOCKS5">SOCKS5</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group id="use" className="mb-2">
                    <Form.Label>IP Address (Whitelist)</Form.Label>
                    <InputGroup >
                      <InputGroup.Text  className="text-gray-600"><GlobeIcon className="icon icon-xs" /></InputGroup.Text>
                      <Form.Control  name="whitelist_ip" type="text" placeholder="The IP address from which you are accessing the proxy" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="use" className="mb-2">
                    <Form.Label>Rotation</Form.Label>
                    <Form.Select name="ip_rotation">
                      <option defaultValue value="1">30 Min</option>
                      <option value="0">6 Hours</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group id="use" className="mb-2">
                    <Form.Label>How will you use your proxies?</Form.Label>
                    <InputGroup>
                      <InputGroup.Text className="text-gray-600"><DocumentTextIcon className="icon icon-xs" /></InputGroup.Text>
                      <Form.Control type="text" placeholder="Scraping, SEO, Ads, etc..." />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mt-mb-2">Total: $ {valueMobile * mobilePrice * valueDays} - ( {mobilePrice} USD / Port / Day ) </Form.Label>
                  </Form.Group>
                </Form.Group>

                {requestResult &&
                    <Alert variant="info">
                    {requestResult}
                  </Alert>
                  }
                
                <div className="d-grid">
                <Button variant="gray-800" type="submit" disabled={loading}>
                  
                  {loading ?
                  <>
                  <Spinner animation="border" size="sm" />
                  <span className="ms-2">Loading...</span>
                  </>
                  
                   
                   :
                   `Order`
                  }
                  </Button>
                </div>
              </Form>


            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

    </>
  );
};
