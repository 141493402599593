import React, { useState } from "react"
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "contexts/AuthContext"
import { RoutesPages } from "routes";
import BgImage from "assets/img/illustrations/signin.svg";
import { FacebookIcon, GithubIcon, TwitterIcon } from "components/BrandIcons";
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default () => {

  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);


    try {
      setError("")

      setLoading(true)

      await login(data.get('email'), data.get('password'))
      navigate('/dashboard')


    } catch (err) {
      setError("An error occurred while sign in")
      setLoading(false)
    }
  };


  //const [token, setToken] = useState(null);


  return (
    <main>


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
        <Container>
          <p className="text-center">
            <Card.Link onClick={() => window.open("https://proxyhive.io", "_self")} className="d-flex align-items-center justify-content-center">
              <ArrowNarrowLeftIcon className="icon icon-xs me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to Proxy Hive</h3>
                </div>
                {error && <Alert severity="error">{error}</Alert>}
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" name="email" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4" >
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" name="password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-top mb-4">
                      <Card.Link as={Link} to="/forgot-Password" className="small text-end">
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <div className="d-grid">
                    <Button variant="gray-800" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>


                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link onClick={() => window.open("https://proxyhive.io/?product=add-balance", "_self")} className="fw-bold">
                      {` Buy Balance and get credentials `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
