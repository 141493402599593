
import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { useDropzone } from "react-dropzone";
import { CalendarIcon, CreditCardIcon, ChatIcon, CubeTransparentIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Image, Button, InputGroup, Alert } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext"
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import axios from 'axios'; // Asegúrate de haber importado Axios
import { logDOM } from "@testing-library/react";

export const DropFilesForm = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: files => setFiles(files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file)
    })))
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={6} className="dropzone-preview">
        <Image src={preview} className="dropzone-image" />
        <Card.Text className="dropzone-filename">
          {path}
        </Card.Text>
      </Col>
    );
  };

  return (
    <>
      <Form {...getRootProps({ className: "dropzone rounded d-flex align-items-center justify-content-center mb-4" })}>
        <Form.Control {...getInputProps()} />
        <div className="dz-default dz-message text-center">
          <p className="dz-button mb-0">Drop files here to upload</p>
        </div>
      </Form>
      <Row className="dropzone-files">
        {files.map(file => <DropzoneFile key={file.path} {...file} />)}
      </Row>
    </>
  );
};

export const GeneralInfoForm = (props) => {
  const { updatePassword } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [sucess, setSucess] = useState('');

  const handleSave = async () => {
    if (newPassword !== confirmNewPassword) {
      setError('Confirmation of new password does not match');
      return;
    }

    if (newPassword.length < 6) {
      setError('Password should be at least 6 characters');
      return;
    }

    setError('');
    setSucess('')

    try {
      const result = await updatePassword(currentPassword, newPassword);
      if (result) {
        setSucess("Password successfully updated");
        // Si tienes alguna lógica adicional aquí, puedes agregarla.
      }
    } catch (error) {
      //console.error(error.message);
      setError('There was an error updating the password');
      // Aquí puedes enviar el error a Firebase Analytics u otra plataforma de seguimiento de errores si lo deseas.
    }
  };

  return (
    <Card border="0" className="shadow mb-4">
      <Card.Body>
      <h5 className="mb-4">General information</h5>
      <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="text" placeholder={props.data.email} disabled={true} />
              </Form.Group>


            </Col>
            <Col md={2} className="mb-3">
              <Form.Group id="balance">
                <Form.Label>Balance</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={`$${props.data.balance}`}
                  disabled={true}
                  style={{ textAlign: "center" }} // Aquí se aplica el centrado
                />
              </Form.Group>
            </Col>

          </Row>
        <h5 className="my-4">Update Password</h5>
        <Row>
          <Col sm={4} className="mb-3">
            <Form.Group id="currentPassword">
              <Form.Control
                required
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={4} className="mb-3">
            <Form.Group id="newPassword">
              <Form.Control
                required
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={4} className="mb-3">
            <Form.Group id="confirmNewPassword">
              <Form.Control
                required
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        {error && <Alert variant="danger">{error}</Alert>}
        {sucess && <Alert variant="success">{sucess}</Alert>}
        <div className="mt-3">
          <Button
            variant="gray-800"
            type="button"
            className="mt-2 animate-up-2"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
        
      </Card.Body>
      
    </Card>
    
  );
};

  export const Coinbase = () => {
    const [usdToDeposit, setUsdToDeposit] = useState('');
    const [error, setError] = useState(false);
    const [hostedUrl, setHostedUrl] = useState('');

  const handleDeposit = async () => {
    setError(false)
    if (parseFloat(usdToDeposit) < 10) {
      setError(true)
      return
    }

    try {
      const response = await axios.post('https://api.commerce.coinbase.com/charges', {
        name: 'Proxy Hive add balance',
        description: 'Add balance to your Proxy Hive account.',
        pricing_type: 'fixed_price',
        local_price: {
          currency: 'USD',
          amount: parseFloat(usdToDeposit),
        },
        metadata: {
          customer_id: "MGCAgPU94CZBYu1Ib9Hb1gHLNlD2",
          customer_email: "support@proxyhive.io",
        },
        cancel_url: 'https://app.proxyhive.io/billing',
        redirect_url: 'https://app.proxyhive.io/billing',
      }, {
        headers: {
          'X-CC-Api-Key': 'b3eb1ed3-13e4-4d69-b200-5b8c99cd0272',
        },
      });

      // Extraer la URL alojada del payload de respuesta
      const hostedUrl = response.data.data.hosted_url;
      setHostedUrl(hostedUrl);

      // Abrir la URL en una nueva pestaña
      window.open(hostedUrl);
    } catch (error) {
      console.error('Error al realizar el depósito:', error);
    }
  };

  return (
    <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
        <Row>

        <Col xs={12} lg={6} xl={4}>
                <Form.Group id="usdToDeposit">
        <Form.Label>Deposit with cryptocurrencies</Form.Label>
        <p>Via coinbase popular cryptocurrencies</p>
        <InputGroup>
          <Form.Control
            required
            type="number"
            placeholder="Enter the amount in USD"
            value={usdToDeposit}
            onChange={(e) => setUsdToDeposit(e.target.value)}
          />
        </InputGroup>
      </Form.Group>
      <p></p>
      {error &&
            <Alert variant="danger" className="mb-2">
              The minimum amount is 10 USD
            </Alert>
          }
      <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center animate-up-2 mt-2" onClick={() => handleDeposit()}>
            <CubeTransparentIcon className="icon icon-xs text-gray-400 me-2" /> Pay with crypto
          </Button>
          
        </Col>
        <Col xs={12} lg={6} xl={4}>
                <Form.Group id="usdToDeposit">
        <Form.Label>Deposit with card</Form.Label>
        <p>Available to us residents only</p>
      </Form.Group>
      <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center animate-up-2 mt-2" onClick={()=> {window.open("https://proxyhive.io/?product=add-balance", '_blank'); }}>
            <CreditCardIcon className="icon icon-xs text-gray-400 me-2" /> Pay with credit
          </Button>
        </Col>
        <Col xs={12} lg={6} xl={4}>
                <Form.Group id="usdToDeposit">
        <Form.Label>Looking for another payment method?</Form.Label>
      </Form.Group>
      <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center animate-up-2 mt-2" onClick={()=> {window.open("https://t.me/proxy_hive", '_blank'); }}>
            <ChatIcon className="icon icon-xs text-gray-400 me-2" /> Get in touch
          </Button>
        </Col>
        </Row>

    </Card>
  );
  };